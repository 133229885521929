@import "app/styles/variables";

html,
body {
  height: 100%;
  overflow-x: hidden;
  font-size: 16px;
}

.tooltip::before {
  @apply z-50;
}

wave {
  height: 100% !important;
}

.wavesurfer-region {
  @apply transition-colors;
  handle.wavesurfer-handle {
    width: 1px !important;
  }
  &[data-region-selected="true"] {
    @apply animate-pulse;
  }

  &[data-region-selected="false"] {
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: hsl(var(--bc));
  -webkit-box-shadow: 0 0 0 1000px hsl(var(--b1)) inset;
  @apply input-primary;
}

::-webkit-scrollbar {
  height: 8px;
  width: 6px;
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  height: 8px;
  width: 6px;
  @apply bg-base-content bg-opacity-40 rounded-box;
}
